<template>
    <v-dialog v-model="visible" persistent max-width="800px">
        <v-card class="mx-auto pr-5 pl-5 pb-3" outlined style="overflow-x: auto; min-width: 600px;">
            <v-card-title class="justify-center">
                <v-spacer/>
                Cadastro de Etapas
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <div style="display: flex; justify-content: center;">
                <v-btn class="mb-2" color="success" text outlined @click="addStep">
                    <v-icon>mdi-plus</v-icon>nova etapa
                </v-btn>
            </div>
            <v-card-text :style="`overflow-y: auto; height: ${$vuetify.breakpoint.height - 190}px; min-width: 600px;`">
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="card"
                    height="100"
                />
                <v-expansion-panels v-model="panel" v-else>
                    <v-expansion-panel v-for="(passo, i) in passos" :key="i">
                        <v-expansion-panel-header>
                            <div style="display: flex; align-items: flex-end;">
                                <div>
                                    <v-btn
                                        icon
                                        color="error"
                                        title="Excluir"
                                        @click.stop="deleteStep(passo.id, i)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                                <!-- <div class="mb-1">
                                    <v-switch v-model="passo.ativo" inset class="ml-4" hide-details dense @click.stop/>
                                </div> -->
                                <div>
                                    <v-btn
                                        icon
                                        color="primary"
                                        @click.stop="duplicateStep(i)"
                                        title="Duplicar"
                                    >
                                        <v-icon>mdi-plus-circle-multiple-outline</v-icon>
                                    </v-btn>
                                </div>
                                <div>
                                    <v-btn
                                        icon
                                        color="success"
                                        title="Salvar"
                                        @click.stop="saveStep(i)"
                                        :loading="loadingSalvar"
                                    >
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </div>
                                <div class="ml-5 mb-2" style="font-weight: bold; text-transform: uppercase;">
                                    {{ passo.id ? `${passo.id} - ` : '' }} {{passo.descricao}}
                                </div>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pt-4">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="passo.id"
                                        dense
                                        outlined
                                        readonly
                                        filled
                                        label="Código"
                                        placeholder="auto"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="12" sm="5">
                                    <v-text-field
                                        v-model="passo.descricao"
                                        dense
                                        outlined
                                        label="Descrição"
                                        placeholder="Sabores"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="12" sm="5">
                                    <v-text-field
                                        v-model="passo.titulo"
                                        dense
                                        outlined
                                        label="Titulo (aparece no cardápio digital)"
                                        placeholder="Escolha os sabores"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-model="passo.forma_calculo"
                                        :items="['Soma', 'Média', 'Maior valor']"
                                        label="Forma de cálculo"
                                        dense
                                        outlined
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-model="passo.formato_quantidade"
                                        :items="['Fração', 'Unidade']"
                                        label="Formatação da quantidade"
                                        dense
                                        outlined
                                        hide-details
                                    ></v-select>
                                    <span style="font-size: 12px; color: #888">Exemplo: Fração (1/4), Unidade (2x)</span>
                                </v-col>
                            </v-row>

                            <div style="display: flex; align-items: baseline;" class="mt-4">
                                <v-menu offset-y max-height="600">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in getCategorias"
                                            :key="index"
                                            @click="selectAll(item)"
                                            style="cursor: pointer;"
                                        >
                                            <v-list-item-title>
                                                Selecionar todos {{ item }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-autocomplete
                                    @keypress.enter="addComplementos(i)"
                                    v-model="values"
                                    :items="getComplementos"
                                    label="Selecione ou cadastre um novo"
                                    prepend-inner-icon="mdi-magnify"
                                    return-object
                                    hide-details
                                    chips
                                    dense
                                    multiple
                                    clearable
                                    outlined
                                    small-chips
                                    deletable-chips
                                >
                                    <template #append-item>
                                        <div
                                            style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                            @click="newComplemento"
                                        >
                                            <v-icon>mdi-plus</v-icon> Cadastrar novo complemento
                                        </div>
                                    </template>
                                    <template #append>
                                        <v-btn
                                            @click="newComplemento"
                                            small
                                            outlined
                                        >
                                            <v-icon>mdi-plus</v-icon> Novo
                                        </v-btn>
                                    </template>
                                </v-autocomplete>
                                <v-btn
                                    @click="addComplementos(i)"
                                    :disabled="!values.length"
                                    color="primary"
                                    class="ml-1"
                                    depressed
                                >
                                    <v-icon>mdi-plus</v-icon>Adicionar
                                </v-btn>
                            </div>
                            <div style="display: flex; align-items: center;" class="mt-2">
                                <div
                                    style="font-weight: bold;"
                                    v-if="hasComplementos(passo.complementos)"
                                >
                                    Complementos
                                </div>
                                <v-btn
                                    @click="sortComplementos(i)"
                                    color="primary"
                                    icon
                                >
                                    <v-icon>mdi-sort-alphabetical-ascending</v-icon>
                                </v-btn>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            small
                                            :color="blockDraggable ? '' : 'primary'"
                                            @click="blockDraggable = !blockDraggable"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>
                                                {{ blockDraggable ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline' }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ blockDraggable ? 'Desbloquar ordenação' : 'Salvar ordenação' }}</span>
                                </v-tooltip>
                            </div>
                            <draggable
                                v-model="passo.complementos"
                                :disabled="blockDraggable"
                                class="mb-12"
                            >
                                <div
                                    class="ml-6 mr-6"
                                    style="display: flex;"
                                    v-for="(c, ia) in passo.complementos"
                                    :key="ia"
                                >
                                    <v-icon style="cursor: move;">mdi-drag-vertical</v-icon>
                                    <v-text-field
                                        v-model="c.descricao"
                                        dense
                                        outlined
                                        filled
                                        readonly
                                        hide-details
                                    />
                                    <v-text-field
                                        v-model="c.valor"
                                        prefix="R$"
                                        solo
                                        dense
                                        label="Valor Adicional"
                                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                        style="width: 120px;"
                                        hide-details
                                    />
                                    <v-text-field
                                        v-model="c.min"
                                        solo
                                        dense
                                        label="Min"
                                        type="number"
                                        style="width: 100px;"
                                        hide-details
                                    />
                                    <v-text-field
                                        v-model="c.max"
                                        solo
                                        dense
                                        label="Max"
                                        type="number"
                                        style="width: 100px;"
                                        hide-details
                                    />
                                    <v-btn icon color="error" @click="remove(ia, i)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                                <div style="display: flex; justify-content: space-evenly;" class="mt-4">
                                    <v-btn
                                        @click="deleteStep(passo.id, i)"
                                        color="error"
                                        small
                                    >
                                        <v-icon>mdi-delete</v-icon> Excluir
                                    </v-btn>
                                    <v-btn
                                        @click="duplicateStep(i)"
                                        color="primary"
                                        small
                                    >
                                        <v-icon>mdi-plus-circle-multiple-outline</v-icon> Duplicar
                                    </v-btn>
                                    <v-btn
                                        @click="saveStep(i)"
                                        :loading="loadingSalvar"
                                        color="success"
                                        small
                                    >
                                        <v-icon>mdi-content-save</v-icon> Salvar
                                    </v-btn>
                                </div>
                            </draggable>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>

        <CadastroComplementos
            :visible="dialog"
            @save="onSaveComplemento"
            @close="closeCadastroComplemento"
        />
    </v-dialog>
</template>

<script>
import CadastroComplementos from './CadastroComplementos';
import draggable from 'vuedraggable';

export default {
    name: 'Steps',

    components: { CadastroComplementos, draggable },

    props: ['visible'],

    data: () => ({
        loadingSalvar: false,
        loading: false,
        dialog: false,
        blockDraggable: true,
        panel: '',
        values: [],
        complementos: [],
        passos: [],
    }),

    computed: {
        getComplementos() {
            // Ordena o array e insere as divisorias por categoria
            let categorias = [];
            const complementos = JSON.parse(JSON.stringify(this.complementos));

            const sorted = complementos.sort((a, b) => {
                let catA = a.categoria?.id;
                let catB = b.categoria?.id;

                return (catA > catB) ? 1 : ((catB > catA) ? -1 : 0)
            });

            sorted.forEach(s => {
                let cat = s.categoria?.descricao;
                !categorias.includes(cat) && categorias.push(cat)
            });

            categorias.forEach(c => {
                const index = sorted.findIndex(i => c == i.categoria?.descricao);
                sorted.splice(index, 0, {header: c});
            });

            return sorted;
        },

        getCategorias() {
            return [...new Set(this.complementos.map(item => item.categoria?.descricao))];
        },
    },

    mounted() {
        this.initialize();
    },

    methods: {
        initialize() {
            this.consultar();
            this.fillComplementos();
        },

        consultar() {
            this.loading = true;
            this.$http.get('passos').then(resp => {
                this.passos = resp.data.data;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            })
            .finally(() => (this.loading = false));
        },

        fillComplementos() {
            this.complementos = [];
            this.$http.get('complementos').then(resp => {
                resp.data.data.forEach(c => {
                    this.complementos.push({
                        value: c.id,
                        text: `${c.descricao} | ${c.categoria.descricao} | R$ ${this.formatPrice(+c.valor || 0)}`,
                        descricao: c.descricao,
                        detalhes: c.detalhes,
                        categoria: c.categoria,
                        valor: c.valor,
                    });
                });
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        addComplementos(index) {
            this.values.forEach(v => {
                this.passos[index].complementos.push({
                    id: null,
                    complemento_id: v.value,
                    descricao: v.descricao,
                    detalhes: v.detalhes,
                    // categoria: v.categoria,
                    ativo: true,
                    min: v.min || '',
                    max: v.max || '',
                    valor: (+v.valor || 0).toFixed(2),
                });
            });

            this.values = [];
        },

        sortComplementos(index) {
            if (!confirm('Deseja realmente ordenar por ordem alfabética?')) {
                return;
            }

            const alphabeticalSorter = (a, b) => a.descricao.localeCompare(b.descricao);
            this.passos[index].complementos.sort(alphabeticalSorter);
            this.$forceUpdate();
        },

        remove(ia, i) {
            this.passos[i].complementos.splice(ia, 1);
        },

        saveStep(i) {
            const data = this.passos[i];
            const id = data.id;

            if (!data.complementos.length) {
                this.notify('Nenhum complemento adicionado na etapa', 'warning');
                return;
            } else {
                const item = data.complementos.find(e => e.valor === '');
                if (item) {
                    this.notify(`Informe o valor de ${item.descricao}`, 'warning');
                    return;
                }
            }

            if (!data.descricao) {
                this.notify('Informe a descrição', 'warning');
                return;
            }

            if (!data.titulo) {
                this.notify('Informe o título', 'warning');
                return;
            }

            if (!data.forma_calculo) {
                this.notify('Informe a forma de cálculo', 'warning');
                return;
            }

            if (!data.formato_quantidade) {
                this.notify('Informe a formatação da quantidade', 'warning');
                return;
            }

            if (id) {
                this.loadingSalvar = true;
                this.$http.put(`passos/${id}`, data).then(() => {
                    this.$emit('save');
                    this.notify('Atualizado com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loadingSalvar = false));
                return;
            }

            this.loadingSalvar = true;
            this.$http.post('passos', data).then(() => {
                this.$emit('save');
                this.notify('Salvo com sucesso!');
                this.consultar();
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loadingSalvar = false));
        },

        addStep() {
            this.passos.unshift({
                id: null,
                descricao: '',
                detalhes: '',
                titulo: '',
                forma_calculo: '',
                formato_quantidade: '',
                complementos: []
            });
            this.panel = 0; //expand only the first
        },

        async deleteStep(id, index) {
            if (!id) {
                this.passos.splice(index, 1);
                return;
            }

            if (await this.$root.$confirm('Confirmação', 'Deseja realmente excluir a etapa?', { color: 'red darken-1' })) {
                this.$http.delete(`passos/${id}`).then(() => {
                    this.$emit('delete', id);
                    this.notify('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                });
            }
        },

        async duplicateStep(i) {
            if (!await this.$root.$confirm('Confirmação', 'Deseja realmente duplicar a etapa?', { color: 'red darken-1' })) {
                return;
            }
            const passo = JSON.parse(JSON.stringify(this.passos[i]));
            console.log(passo)
            passo.id = null;
            passo.descricao = passo.descricao + ' - Cópia';
            passo.complementos.forEach(c => {
                c.complemento_id = c.pivot.complemento_id;
                c.id = null;
            });
            this.passos.push(passo);
        },

        hasComplementos(complementos) {
            return complementos?.length;
        },

        newComplemento() {
            this.dialog = true;
        },

        closeCadastroComplemento() {
            this.dialog = false;
        },

        onSaveComplemento() {
            this.fillComplementos();
            this.notify('Salvo com sucesso!');
        },

        selectAll(categoria) {
            this.complementos.forEach(e => {
                if (e.categoria?.descricao == categoria) {
                    this.values.findIndex(v => v.descricao == e.descricao) === -1
                        && this.values.push(e);
                }
            });
        },
    },
}
</script>

<style scoped>
.v-expansion-panel-header--active {
    background-color: #ddd !important;
}
</style>
