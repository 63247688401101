<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <v-spacer/>
                <span class="text-h5">Cadastrar novo complemento</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-container>
                    <v-row>
                        <v-col cols="8">
                            <v-text-field
                                v-model="descricao"
                                @keypress.enter="$refs.valor.focus()"
                                label="Descrição"
                                ref="descricao"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="valor"
                                @keypress.enter="$refs.detalhes.focus()"
                                prefix="R$"
                                label="Valor adicional"
                                ref="valor"
                                outlined
                                dense
                                hide-details
                                placeholder="0.00"
                                v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="detalhes"
                                @keypress.enter="$refs.categoria.focus()"
                                clear-icon="mdi-backspace-outline"
                                label="Detalhes/Ingredientes"
                                ref="detalhes"
                                dense
                                hide-details
                                clearable
                                outlined
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                prepend-inner-icon="mdi-magnify"
                                v-model="categoria"
                                :items="categorias"
                                :search-input.sync="searchInput"
                                @keypress.enter="$refs.salvar.$el.focus()"
                                item-text="descricao"
                                item-value="id"
                                ref="categoria"
                                return-object
                                dense
                                label="Categoria"
                                placeholder="Selecione ou cadastre uma nova"
                                outlined
                                hide-details
                            >
                                <template #append>
                                    <v-btn
                                        v-if="searchInput && !categorias.includes(searchInput)"
                                        @click="saveCategoria"
                                        small
                                        dense
                                        depressed
                                        outlined
                                    >
                                        <v-icon>mdi-plus</v-icon>Adicionar
                                    </v-btn>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="mb-6" style="border: solid 1px #999; border-radius: 5px;">
                                <b>Imagem</b>
                                <ImageInput ref="imagem" v-model="imagens"/>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="warning" text @click="$emit('close')">
                    Cancelar
                </v-btn>
                <v-spacer />

                <v-btn
                    color="success"
                    @click="saveComplemento"
                    @keypress.enter="saveComplemento"
                    :loading="loading"
                    ref="salvar"
                >
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ImageInput from '@/components/ImageInput';

export default {
    name: 'CadastroComplementos',

    components: {
        ImageInput,
    },

    props: ['visible'],

    data: () => ({
        loading: false,
        descricao: '',
        valor: '',
        detalhes: '',
        searchInput: '',
        categoria: null,
        categorias: [],
        imagens: [],
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        visible(v) {
            v && setTimeout(() => this.$refs.descricao.focus(), 100);
        },
    },

    methods: {
        consultar() {
            this.$http.get('categorias').then(resp => {
                this.categorias = resp.data.data;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        saveCategoria() {
            if (!this.searchInput) {
                this.notify('Informe o nome da categoria', 'warning');
                return;
            }

            const cat = this.categorias.find(e => e.descricao === this.searchInput);
            if (cat) {
                this.categoria = cat;
                return;
            }

            this.$http.post('categorias', {descricao: this.searchInput}).then(resp => {
                const categoria = resp.data.data;
                this.categorias.push(categoria);
                this.categoria = categoria;
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        saveComplemento() {
            if (this.loading) {
                return;
            }

            if (!this.descricao) {
                this.notify('Informe a descrição', 'warning');
                this.$refs.descricao.focus();
                return;
            }

            if (!this.categoria) {
                this.notify('Informe a categoria', 'warning');
                this.$refs.categoria.focus();
                return;
            }

            const data = {
                status: 'ativo',
                ativo: true,
                descricao: this.descricao,
                detalhes: this.detalhes,
                categoria_id: this.categoria.id,
                valor: this.valor || 0,
                imagens: this.imagens,
            };

            this.loading = true;
            this.$http.post('complementos', data).then(() => {
                this.$emit('save', data);
                this.descricao = '';
                this.detalhes = '';
                this.valor = '';
                this.imagens = [];
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => {
                this.loading = false;
                this.$refs.descricao.focus();
            });
        },
    },
}
</script>
